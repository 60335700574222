<script>

export default {
  render: () => null,
  data() {
    return {
      connection: null,
      timeoutInterval: 250,
      connectInterval: null,
    };
  },
  props: {
    broadcast: { Type: Object, requires: true },
    playerId: { Type: String, required: false, default: 'default' },
    isPlaying: { Type: Boolean, required: false, default: false },
  },
  watch: {
    isPlaying(newVal) {
      this.sendStateToRemote(newVal);
    },
  },
  methods: {
    sendStateToRemote(isPlaying) {
      if (isPlaying) {
        this.send(this.broadcast.state);
      } else {
        this.send('inactive');
      }
    },
    check() {
      // check if websocket instance is closed, if so call `connect` function.
      if (!this.connection || this.connection.readyState === WebSocket.CLOSED) {
        this.connect();
      }
    },
    connect() {
      if (this.connection != null && this.connection.readyState !== WebSocket.CLOSED) {
        this.connection.close();
      }
      this.connection = new WebSocket(
        `${process.env.VUE_APP_MEMORIAM_TV_LIVE_COUNTER_HOST}/counter/${this.broadcast.id}?playerId=${this.playerId}&appVersion=${process.env.VUE_APP_COMMIT_ID || 'development'}`,
      );

      this.connection.onopen = () => {
        this.sendStateToRemote(this.isPlaying);
        this.timeoutInterval = 250; // reset timer to 250 on open of websocket connection
        clearTimeout(this.connectInterval); // clear Interval on on open of websocket connection
      };
      this.connection.onclose = () => {
        this.timeoutInterval += this.timeoutInterval; // increment retry interval
        const maximumTimeOut = Math.min(10000, this.timeoutInterval);
        // eslint-disable-next-line no-console
        console.log(
          `Socket is closed. Reconnect will be attempted in ${
            maximumTimeOut / 1000
          } seconds.`,
        );

        // eslint-disable-next-line max-len
        this.connectInterval = setTimeout(this.check, maximumTimeOut); // call check function after timeout
      };
    },
    send(message) {
      this.waitForConnection(message, 1000);
    },
    waitForConnection(message, interval) {
      const that = this;
      if (this.connection.readyState === WebSocket.OPEN) {
        this.connection.send(message);
      } else {
        // optional: implement backoff for interval here
        setTimeout(() => {
          that.waitForConnection(message, interval);
        }, interval);
      }
    },
  },
  mounted() {
    this.connect();
  },
  beforeDestroy() {
    try {
      this.connection.close();
    } catch (error) {
      // console.error(error);
    }
  },
};
</script>
