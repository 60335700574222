<template>
  <div class="dot-spinner--wrapper">
    <div :class="spinnerClass">
      <div class="double-bounce theme--bg" />
      <div class="double-bounce double-bounce--delayed theme--bg" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    gapped: { Type: Boolean, default: false },
  },
  computed: {
    spinnerClass() {
      return {
        'dot-spinner': true,
        gapped: this.gapped,
      };
    },
  },
};
</script>

<style >
@keyframes spinner-rotate {
  100% {
    transform: rotate(360deg);
  }
}

.spinner-bordered {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-rotate 1.5s linear infinite;
  animation: spinner-rotate 1.5s linear infinite;
}

.dot-spinner {
  width:  20px;
  height: 20px;
  position: relative;
  top: -10px;
  left: -10px;
}

.dot-spinner .double-bounce {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333333;
  opacity: 0.6;
  position: absolute;
  top: 0px;
  left: 0px;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}
.dot-spinner .double-bounce--delayed {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.dot-spinner.gapped {
  display: inline-block;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: #0000;
  border-radius: 50%;
  -webkit-animation: spinner-rotate 1.5s linear infinite;
  animation: spinner-rotate 1.5s linear infinite;
  filter: drop-shadow(0 0 0.5rem white);
}
.dot-spinner.gapped .double-bounce {
  display: none;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

/* xs Default */
/* sm Small devices (tablets, 768px and up) */
/* md Medium devices (desktops, 992px and up) */
/* lg Large devices (large desktops, 1120 and up) */
@media only screen and (min-width: 576px) {
  .dot-spinner {
    width:  30px;
    height: 30px;
    top: -15px;
    left: -15px;
  }
}
@media only screen and (min-width: 768px) {
  .dot-spinner {
    width:  40px;
    height: 40px;
    top: -20px;
    left: -20px;
  }
}
@media only screen and (min-width: 1120px) {
  .dot-spinner {
    width:  60px;
    height: 60px;
    top: -30px;
    left: -30px;
  }
}

</style>
