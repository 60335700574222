<template>
  <section v-if="loading" class="loading">
    <p>
      Een moment geduld alstublieft, de pagina wordt geladen.
    </p>
  </section>
  <section v-else-if="errored" class="errored">
    <ErrorMessage :error="error" title="Ongeldige URL"/>
  </section>
  <section v-else-if="gone">
    <h3 class="memoriam-tv--text-danger mt-4">De link is niet meer geldig.</h3>
    <transition name="slide">
      <section v-show="gone">
        <b-row class="justify-content-center">
          <b-col cols="10" md="8" xl="6" class="text-left">
            <p>Dit kan verschillende oorzaken hebben:</p>
            <dl>
              <dt>De opname is verwijderd</dt>
              <dd>
                Opnames blijven normaal 1 maand beschikbaar op ons platform.
              </dd>
              <dt>De uitzending is geannuleerd.</dt>
              <dd>
                Er is besloten om geen uitzending plaats te laten vinden.
              </dd>
              <p v-if="memoriamDomain()">
                Neem voor meer informatie contact op met de uitvaartverzorger.
              </p>
            </dl>
          </b-col>
        </b-row>
      </section>
    </transition>
  </section>
  <section v-else class="player-page">
    <live-counter
      :broadcast="broadcast"
      :playerId="playerId"
      :isPlaying="!showClickToStartMessage"
    />
    <Header :broadcast="broadcast" :currentBrand="currentBrand" />
    <b-container>
      <h1>{{ broadcast.name }}</h1>
      <b-row cols="12" v-if="message" id="message">
        <b-col cols="12" v-html="message" class="mt-3"/>
      </b-row>
      <b-row v-if="hasAsset()" class="mb-3">
        <b-col v-if="videoExpired()">
          Terugkijken niet meer mogelijk, de video is verwijderd.
        </b-col>
        <b-col v-else id="videoPlayerWrapper" class="justify-content-center">
          <VideoPlayer
            :broadcast="broadcast"
            :playerId="playerId"
            :currentBrand="currentBrand"
            @livestream-ended="livestreamEnded"
            @show-click-to-start-message="showClickToStartMessage = arguments[0]"
            @playing="playing = arguments[0]"
          />
        </b-col>
      </b-row>
      <b-row v-if="hasAsset() && !videoExpired()">
        <transition name="fade" v-if="showClickToStartMessage">
          <b-col class="pulse">
            <p>Klik op de afbeelding om te beginnen met afspelen.</p>
          </b-col>
        </transition>
      </b-row>
      <b-row v-if="onDemand() && !videoExpired()">
        <transition name="fade">
          <b-col>
            <p>De uitzending is terug te kijken tot {{ expiresAtDay() }}.</p>
          </b-col>
        </transition>
      </b-row>
      <b-row v-else-if="ended() && pastStopAt()">
        <b-col cols="12">
          <p>
            De uitzending is afgelopen, terugkijken niet meer mogelijk.
          </p>
        </b-col>
      </b-row>
      <b-row v-else-if="!isLive() && !videoExpired()">
        <b-col cols="12" v-if="startMessage" id="start_message" v-html="startMessage"/>
        <b-col cols="12" v-else-if="aboutToStart()">
          <p>
            De live uitzending begint om {{ startAtTime() }} uur
          </p>
        </b-col>
        <b-col cols="12" v-else>
          <p>
            De live uitzending begint {{ startAtDay() }} om {{ startAtTime() }} uur
          </p>
        </b-col>
        <b-col cols="12" v-if="aboutToStart()">
          <p>
            We wachten op signaal vanuit de locatie.
          </p>
        </b-col>
        <b-col cols="12" id="heroImage" :class="heroImageState"
          class="embed-responsive embed-responsive-16by9 mb-4">
          <div id="filler" class="embed-responsive-item">
            <Spinner id="spinnerWrapper" :gapped="showOpenSpinner"
            v-if="showSpinnerOnPosterImage"
            class="d-flex justify-content-center"/>
            <img
              v-if="broadcast.posterImage1080Url"
              :src="broadcast.posterImage1080Url"
              :alt="name"
            />
          </div>
        </b-col>
        <b-col cols="12" v-if="showTestStreamButton()">
          <p class="">
            <router-link
              class="btn btn-sm btn-outline-dark theme--bg"
              to="teststream"
              append
              >Livestream Test</router-link
            >
          </p>
        </b-col>
      </b-row>
      <b-row v-else-if="deleted">
        <b-col cols="12">
          <p>
            Terugkijken niet meer mogelijk, de video is verwijderd.
          </p>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';
import Broadcast from '../models/Broadcast';
import ErrorMessage from './ErrorMessage.vue';
import LiveCounter from '../components/LiveCounter.vue';
import Header from '../components/Header.vue';
import Spinner from '../components/Spinner.vue';
import currentBrandMixin from '../mixins/currentBrandMixin';
import domainChecker from '../mixins/domainChecker';
import messageMixin from '../mixins/messageMixin';
import broadcastHelperMixin from '../mixins/broadcastHelperMixin';

export default {
  mixins: [
    currentBrandMixin,
    domainChecker,
    messageMixin,
    broadcastHelperMixin,
  ],
  components: {
    ErrorMessage,
    VideoPlayer: () => import(/* webpackPrefetch: true, webpackChunkName: "VideoPlayer" */ '../components/VideoPlayer.vue'),
    LiveCounter,
    Header,
    Spinner,
  },
  data() {
    return {
      broadcast: {
        name: '',
      },
      loading: true,
      deleted: false,
      error: null,
      errored: false,
      gone: false,
      invalid: false,
      isLivestreamEnded: false,
      showClickToStartMessage: true,
      playing: false,
      timer: null,
      shareId: null,
      playerId: this.$cookies.get('playerId'),
    };
  },
  props: {
    embed: { Type: Boolean, default: false },
  },
  methods: {
    hasAsset() {
      return this.broadcast.state === 'live' || this.broadcast.state === 'on_demand';
    },
    showTestStreamButton() {
      return !this.aboutToStart();
    },
    aboutToStart() {
      return this.encoderConnectionStart() < new Date();
    },
    encoderConnectionStart() {
      const { startAt, connectionWindow } = this.broadcast;
      return new Date(startAt) - (connectionWindow * 1000);
    },
    startAtDay() {
      return format(new Date(this.broadcast.startAt), 'd MMMM yyyy', { locale: nl });
    },
    startAtTime() {
      return format(new Date(this.broadcast.startAt), 'HH:mm', { locale: nl });
    },
    ended() {
      return this.broadcast.state === 'ended';
    },
    onDemand() {
      return this.broadcast.state === 'on_demand';
    },
    isLive() {
      return this.broadcast.state === 'live';
    },
    expiresAtDay() {
      return format(new Date(this.broadcast.expiresAt), 'd MMMM yyyy', { locale: nl });
    },
    videoExpired() {
      return new Date(this.broadcast.expiresAt) < new Date();
    },
    pastStopAt() {
      return new Date(this.broadcast.stopAt) < new Date();
    },
    livestreamEnded(state) {
      this.isLivestreamEnded = state;
    },
    startRequestTimer() {
      this.requestTimer(); // initial call
      this.timer = setInterval(() => this.requestTimer(), 5000);
    },
    requestTimer() {
      if (this.gone) {
        clearTimeout(this.timer);
      }
      if (!this.requestPending) {
        this.requestPending = true;
        this.retrieveBroadcast();
      }
    },
    retrieveBroadcast() {
      Broadcast.where({ shareId: this.discoverShareId() }).all().then((response) => {
        // eslint-disable-next-line prefer-destructuring
        this.broadcast = response.data[0];
        this.errored = false;
        this.error = null;
      })
        .catch((error) => {
          if (error.response?.status === 410) {
            // eslint-disable-next-line no-console
            console.error('Gone', error);
            this.gone = true;
          } else {
            // eslint-disable-next-line no-console
            console.error(error);
            this.errored = true;
          }
          this.error = error;
        })
        .finally(() => {
          this.loading = false;
          this.requestPending = false;
        });
    },
  },
  computed: {
    showSpinnerOnPosterImage() {
      return !this.broadcast?.posterImage1080Url || this.showOpenSpinner;
    },
    name() {
      return this.currentBrand?.name;
    },
    heroImageState() {
      return {
        faded: this.aboutToStart(),
      };
    },
    showOpenSpinner() {
      return this.aboutToStart();
    },
  },
  mounted() {
    this.startRequestTimer();
  },
  watch: {
    'broadcast.state': function broadcastState(newVal) {
      this.deleted = (newVal === 'deleted');
    },
    'broadcast.name': function broadcastState(newVal) {
      document.title = newVal;
    },
  },
};
</script>

<style scoped>
@keyframes fade-in {
  0%   { opacity: 0; display: none;}
  50%  { opacity: 0;}
  60%  { display: block;}
  100% { opacity: 1; display: none;}
}
@keyframes pulse {
  0%   { background-color: #fff;    color: #000000}
  25%  { background-color: #ff0000; color: #ffffff}
  75%  { background-color: #ff0000; color: #ffffff}
  100% { background-color: #fff;    color: #000000}
}
.pulse {
  color: #000000;
  animation-name: pulse;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}

#videoPlayerWrapper {
  transition: all 1s;
}
#heroImage {
  position: relative;
}
#heroImage.faded img {
  opacity: 0.3;
}

#spinnerWrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  display: flex !important;
}

/* Transitions */
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

#filler {
  height: 100%;
  vertical-align: middle;
}
#filler img {
  max-width:100%;
  max-height:100%;
  vertical-align: middle;
}
</style>
